import $ from 'jquery';
import urls from '../urls';
import Swal from 'sweetalert2'

export default function(){
  $(document).on('click', '.js-add-to-card', function(e){
    e.preventDefault();
    const productID = $(this).data('product-id');
    const parentProductID = $(this).data('parent-product-id') ? $(this).data('parent-product-id') : 0;
    const productType = $(this).data('product-type') ? $(this).data('product-type') : 'product';

    if(productID){
      addToBasket(productID, parentProductID, productType);
    }
  })
  
  $(document).on('change', '.js-update-qty', function(e){
    e.preventDefault();
    const productID = $(this).closest('tr').data('product-id');
    const productQty = $(this).val();
    if(productID){
      updateBasketProductQty(productID, productQty);
    }
  })
  
  $(document).on('click', '.js-remove-product', function(e){
    e.preventDefault();
    const productName = $(this).data('product-title');
    if(productName){
      removeToBasket(productName);
    }
  })
}

const addToBasket = (productid, parentid, producttype) => {
  const requestData = {
    ProcessType: 'add',
    ProductID: productid,
    ProductParentID: parentid,
    ProductType: producttype
  };

  $.ajax({
    url: urls.basket,
    method: 'POST',
    dataType: 'json',
    data: requestData,
    success: function(response) {
      if(response.IsSuccess){
        Swal.fire({
          title: 'Tebrikler',
          text: response.Message,
          icon: 'success',
          timer: 2000
        });

        $('.js-basket-count').text(`(${response.BasketCount})`)
      }else{
        Swal.fire(
          'Hata',
          response.Message,
          'error'
        )
      }
    },
    error: function(err) {
      console.log(err)
    }
  })
}

const updateBasketProductQty = (productid, productQty) => {
  const requestData = {
    ProcessType: 'update',
    ProductID: productid,
    ProductQty: productQty
  };

  $.ajax({
    url: urls.basket,
    method: 'POST',
    dataType: 'json',
    data: requestData,
    success: function(response) {
      if(response.IsSuccess){
        if(response.Redirect){
          window.location.href = response.Redirect;
        }
      }else{
        Swal.fire(
          'Hata',
          response.Message,
          'error'
        )
      }
    },
    error: function(err) {
      console.log(err)
    }
  })
}

const removeToBasket = (product) => {
  const requestData = {
    ProcessType: 'remove',
    ProductID: product
  };

  $.ajax({
    url: urls.basket,
    method: 'POST',
    dataType: 'json',
    data: requestData,
    success: function(response) {
      if(response.IsSuccess){
        Swal.fire({
          title: 'Tebrikler',
          text: response.Message,
          icon: 'success',
          timer: 2000
        });
        
        if(response.Redirect){
          setTimeout(() => {
            window.location.href = response.Redirect
          }, 1000)
        }
      }else{
        Swal.fire(
          'Hata',
          response.Message,
          'error'
        )
      }
    },
    error: function(err) {
      console.log(err)
    }
  })
}
